import { ACCOUNT_STATEMENT, WITHDRAW_LIST, SHOW_HEADER_BALANCE } from "./types";

const initialState = {
  accountStatement: null,
  withdrawList: null,
  showHeaderBalance: true,
};

export default (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ACCOUNT_STATEMENT:
      let data = payload.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return {
        ...state,
        accountStatement: data,
      };
    case WITHDRAW_LIST:
      return {
        ...state,
        withdrawList: payload,
      };
    case SHOW_HEADER_BALANCE:
      return {
        ...state,
        showHeaderBalance: payload,
      };
    default:
      return state;
  }
};
