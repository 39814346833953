import React from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoutes = ({
  isAuth,
  redirectPath = "/mobile-number",
  children,
}) => {
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
export default ProtectedRoutes;
