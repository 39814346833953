import React, { useState } from "react";
import Slider from "react-slick";
import ImageCard from "./imageCard";
import favourite from "../../assets/images/fav.png";

const SliderSection = ({
  isSearched,
  activeCategory,
  data,
  sliderPageNumber,
  isAuth,
  showSlider,
  filteredData,
  setSliderPageNumber,
  setShowSlider,
}) => {
  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={(e) => {
          window.onclick = (ev) => {
            // ev.target.parentNode.parentNode.id;
            handleSlideCounter("inc", ev.target.parentNode.parentNode.id);
          };

          onClick(e);
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={(e) => {
          window.onclick = (ev) => {
            // ev.target.parentNode.parentNode.id;
            handleSlideCounter("dec", ev.target.parentNode.parentNode.id);
          };
          onClick(e);
        }}
      />
    );
  }
  const getShowSlider = (name) => {
    return (
      showSlider?.showMultipleSlides?.[name] && activeCategory === "Overview"
    );
  };
  const handleSlideCounter = (type = "inc", cat) => {
    let filteredItem = Object.values(data)?.find((f) => f.name === cat);

    if (filteredItem) {
      const slideLength = filteredItem && Object.keys(filteredItem)?.length - 1;
      if (type == "inc") {
        if (slideLength === sliderPageNumber[cat]) {
          let obj = sliderPageNumber;
          obj[cat] = 1;
          setSliderPageNumber({ ...obj });
        } else {
          let obj = sliderPageNumber;
          obj[cat] = obj[cat] + 1;
          setSliderPageNumber({ ...obj });
        }
      } else {
        if (sliderPageNumber[cat] === 1) {
          let obj = sliderPageNumber;
          obj[cat] = slideLength;
          setSliderPageNumber({ ...obj });
        } else {
          let obj = sliderPageNumber;
          obj[cat] = obj[cat] - 1;
          setSliderPageNumber({ ...obj });
        }
      }
    }
  };

  const handleShowSliderClick = (name) => {
    let obj = showSlider.showMultipleSlides;
    Object.keys(obj).forEach((key) => {
      if (key !== name) {
        obj[key] = true;
      }
    });
    obj[name] = !obj?.[name];
    setShowSlider((p) => ({
      ...p,
      showMultipleSlides: obj,
    }));
  };
  return (
    <div className="slots-section">
      <div className="games-section">
        {!isSearched ? (
          Object.values(data)
            .filter((f) => {
              if (f?.name === activeCategory || activeCategory === "Overview") {
                return true;
              }
            })
            .map((value) => {
              const slideLength = Object.values(value).filter(
                (f) => typeof f !== "string"
              ).length;
              return (
                <>
                  <div id={value.name}>
                    <div className="game-heading casino-game-heading ">
                      <span>
                        {value?.["name"]}{" "}
                        <span className="game-count">160</span>
                      </span>
                      {slideLength > 1 && (
                        <p
                          className={
                            getShowSlider(value?.name) ? "pagination" : "d-none"
                          }
                        >
                          {sliderPageNumber[value.name]}/{slideLength}
                        </p>
                      )}
                      {activeCategory == "Overview" && (
                        <button
                          className="games-see-all"
                          type="button"
                          onClick={() => {
                            handleShowSliderClick(value?.name);
                          }}
                        >
                          {getShowSlider(value?.name)
                            ? "Show More"
                            : "Show Less"}
                        </button>
                      )}
                    </div>
                    {getShowSlider(value?.name) ? (
                      <Slider {...settings}>
                        {Object.values(value)
                          .filter((f) => typeof f !== "string")
                          .map((item) => {
                            return (
                              <ul>
                                {Object.values(item).map((d) => {
                                  return (
                                    <ImageCard
                                      item={d}
                                      isAuth={isAuth}
                                      favourite={favourite}
                                    />
                                  );
                                })}
                              </ul>
                            );
                          })}
                      </Slider>
                    ) : (
                      <div>
                        <ul>
                          {Object.values(value)
                            .filter((f) => typeof f !== "string")
                            .map((slide) => {
                              return Object.values(slide).map((item) => {
                                return (
                                  <ImageCard
                                    item={item}
                                    isAuth={isAuth}
                                    favourite={favourite}
                                  />
                                );
                              });
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              );
            })
        ) : (
          <div>
            <ul>
              {filteredData &&
                Object.values(filteredData).map((item) => {
                  return (
                    <ImageCard
                      isAuth={isAuth}
                      item={item}
                      favourite={favourite}
                    />
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default SliderSection;
