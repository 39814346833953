import { casinoTabs } from "./casinoTabs.data";
import { slotsData } from "./slots.data";

export const WordCasinoGames = {
  ...slotsData.oneplaytab.slide1,
  ...slotsData.oneplaytab.slide2,

  ...casinoTabs.Supernowa.slide1,
  ...casinoTabs.Supernowa.slide2,
  ...casinoTabs.Supernowa.slide3,
  ...casinoTabs.Vivo.slide1,
  ...casinoTabs.Vivo.slide2,

  // ...slotsData.supernowatab.slide1,
  // ...slotsData.supernowatab.slide2,
};
