import React from "react";

const ImageCard = ({ item, isAuth, favourite }) => {
  return (
    <li className="game-section">
      <a href={isAuth ? item.redirectUrl : "/sign-in"}>
        <img src={item.imgUrl} className="main-img" />
        <div className="d-flex game-name">
          <div>
            <p>{item.name}</p>
            <p className="providernames">{item.providerName}</p>
          </div>
        </div>
      </a>
    </li>
  );
};
export default ImageCard;
