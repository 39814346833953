import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotsData } from "../../../../lib/data/slots.data";
import MainSlider from "../../../../containers/MainSlider";
import { getBanners } from "../../../../redux/app/actions";
import Search from "../../../../assets/svg/SearchIcon";
import SliderSection from "../../../components/sliderSection";
import { Form } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { gsap } from "gsap";

const Slots = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const container = useRef();
  const tl = useRef();
  const { banners } = useSelector((state) => state.app);
  const [searchedText, setSearchText] = useState("");

  const [showSlider, setShowSlider] = useState({
    show: true,
    category: "Overview",
    showMultipleSlides: {
      "Popular Games": true,
      "New Games": true,
      Megaways: true,
      Netent: true,
      "Red Tiger": true,
      // "One Touch": true,
      "One Play": true,
      "TV Games": true,
      "All Games": true,
      // Supernowa: true,
      "Pragmatic Play": true,
    },
  });
  const [sliderPageNumber, setSliderPageNumber] = useState({
    "Popular Games": 1,
    "New Games": 1,
    Megaways: 1,
    Netent: 1,
    "Red Tiger": 1,
    // "One Touch": 1,
    "One Play": 1,
    Supernowa: 1,
    // "TV Games": 1,
    "All Games": 1,
    "Pragmatic Play": 1,
  });
  const [activeCategory, setActiveCategory] = useState("Overview");

  const categoriesTab = [
    "Overview",
    "Popular Games",
    "New Games",
    "Megaways",
    "Netent",
    "Red Tiger",
    // "One Touch",
    "One Play",
    // "Supernowa",
    "Pragmatic Play",
    // "TV Games",
    "All Games",
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanners());
  }, []);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const handleSearch = (e) => {
    setSearchText(e?.target?.value);
    const value = e?.target?.value?.toLowerCase();
    if (value) {
      setIsSearched(true);
      let filteredValues = [];

      Object.values(slotsData).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      setFilteredData(filteredValues);
    } else {
      setIsSearched(false);
    }
  };
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const boxes = self.selector(".dot");
      tl.current = gsap
        .timeline()
        .to(boxes[0], { x: 250 })

        .reverse();
    }, container); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  const toggleTimeline = () => {
    tl.current.reversed(!tl.current.reversed());
  };
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main GamesSection SlotsSectionNew">
        <div className="mainBanner">
          <MainSlider banners={banners} />
        </div>

        <div className="container-fluid containerCss">
          <div className="casino-category filters">
            <div className=""></div>
            <ul>
              <div className="position-relative new-search" ref={container}>
                <input
                  id="search-btn"
                  type="checkbox"
                  onClick={toggleTimeline}
                />
                <label htmlFor="search-btn">
                  <AiOutlineSearch className="lens" />
                  <div className=" dot">
                    <RxCross1
                      className="cross dot"
                      onClick={() => {
                        setIsSearched(false);
                        setSearchText("");
                      }}
                    />
                  </div>
                </label>
                <input
                  id="search-bar"
                  type="text"
                  placeholder="Search..."
                  value={searchedText}
                  onChange={handleSearch}
                />
                <div className="search d-none">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <div className="symbol">
                    <AiOutlineSearch className="lens" />
                  </div>
                </div>
              </div>
              {categoriesTab.map((tab, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setShowSlider((p) => ({
                      category: tab,
                      show: true,
                      ...p,
                    }));
                    setActiveCategory(tab);
                  }}
                >
                  <a
                    href="#"
                    style={
                      activeCategory === tab
                        ? {
                            color: "#fff",
                            borderBottom: "2px solid #ffb900",
                          }
                        : {}
                    }
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <SliderSection
          isSearched={isSearched}
          activeCategory={activeCategory}
          data={slotsData}
          sliderPageNumber={sliderPageNumber}
          isAuth={isAuth}
          showSlider={showSlider}
          filteredData={filteredData}
          setSliderPageNumber={setSliderPageNumber}
          setShowSlider={setShowSlider}
        />
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Slots;
