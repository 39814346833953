import { casinoTabs } from "./casinoTabs.data";
import { slotsData } from "./slots.data";

export const EzugiGames = {
  ...slotsData.netenttab.slide1,
  ...slotsData.netenttab.slide2,
  ...slotsData.redtigertab.slide1,
  ...slotsData.redtigertab.slide2,
  ...slotsData.populargametab.slide1,
  ...slotsData.newgametab.slide1,
  ...slotsData.megawaystabs.slide1,

  ...casinoTabs.roulettetab.slide1,
  ...casinoTabs.roulettetab.slide2,
  // ...casinoTabs.roulettetab.slide3,
  ...casinoTabs.baccarattab.slide1,
  ...casinoTabs.baccarattab.slide2,
  // ...casinoTabs.baccarattab.slide3,
  ...casinoTabs.spribe.slide1,
  ...casinoTabs.pokertab.slide1,

  ...casinoTabs.TvShows.slide1,
  // ...slotsData.TVShows.slide1,

  ...slotsData.AllGames.slide1,
  ...slotsData.AllGames.slide2,
  ...slotsData.AllGames.slide3,
  ...slotsData.AllGames.slide4,
  ...slotsData.AllGames.slide5,
  ...slotsData.AllGames.slide6,
  ...slotsData.AllGames.slide7,
  ...slotsData.AllGames.slide8,
  ...slotsData.AllGames.slide9,
  ...slotsData.AllGames.slide10,

  ...casinoTabs.AllGames.slide1,
  ...casinoTabs.AllGames.slide2,
  ...casinoTabs.AllGames.slide3,
  ...casinoTabs.AllGames.slide4,
  ...casinoTabs.AllGames.slide5,
  ...casinoTabs.AllGames.slide6,
  ...casinoTabs.AllGames.slide7,
  ...casinoTabs.AllGames.slide8,
  ...casinoTabs.AllGames.slide9,

  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab.slide1,
  ...casinoTabs.Blackjacktab.slide2,
  ...casinoTabs.Blackjacktab.slide3,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
};
