import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/spribe.png";
import ProviderLogo5 from "../assets/images/providers/netent.png";
import ProviderLogo6 from "../assets/images/providers/redtiger.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import { Col, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import LiveMatch from "../assets/images/foot/live.png";
import SlotsFoot from "../assets/images/foot/slot-machine.png";
import MyBets from "../assets/images/foot/betting.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return <></>;
};

export default Footer;
