export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  populartab: {
    slide1: {
      AndarBaharVivo: {
        redirectUrl: "/casino/vivo-andar-bahar",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/AndarBaharVivo.jpg",
        alt: "",
        name: "Andar Bahar Vivo",
        providerName: "Vivo",
      },
      TeenPattiVivo: {
        redirectUrl: "/casino/vivo-teen-patti",
        code: "",
        casino: "vivo",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/popular/TeenPattiVivo.jpg",
        alt: "",
        name: "Teen Patti Vivo",
        providerName: "Vivo",
      },
    },
    name: "Popular",
  },
  roulettetab: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
      // AutoRoulettePargmatic: {
      //   redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
      //   code: "vs20stickysymbol",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
      //   alt: "",
      //   name: "Auto Roulette Pragmatic",
      //   providerName: "Pragmatic Play",
      // },
      // Roulette1Azure: {
      //   redirectUrl: "/casino/ezugi/Roulette1Azure",
      //   code: "227",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Roulette1Azure.jpg",
      //   alt: "",
      //   name: "Roulette Azure",
      //   providerName: "Pragmatic Play",
      // },
      // RouletteLobby: {
      //   redirectUrl: "/casino/ezugi/RouletteLobby",
      //   code: "102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/roulletewheel.jpg",
      //   alt: "",
      //   name: "Roulette Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    // slide3: {
    //   AutoRoulettePargmatic: {
    //     redirectUrl: "/casino/ezugi/AutoRoulettePargmatic",
    //     code: "225",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Auto-Roulette-pragmatic.jpg",
    //     alt: "",
    //     name: "Auto Roulette Pragmatic",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette1Azure: {
    //     redirectUrl: "/casino/ezugi/Roulette1Azure",
    //     code: "227",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette1Azure.jpg",
    //     alt: "",
    //     name: "Roulette Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteLobby: {
    //     redirectUrl: "/casino/ezugi/RouletteLobby",
    //     code: "102",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulletewheel.jpg",
    //     alt: "",
    //     name: "Roulette Lobby",
    //     providerName: "Pragmatic Play",
    //   },
    //   PragmaticTurkishRoulette: {
    //     redirectUrl: "/casino/ezugi/PragmaticTurkishRoulette",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/pragturkish.jpg",
    //     alt: "",
    //     name: "Turkish Roulette ",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulettegerman: {
    //     redirectUrl: "/casino/ezugi/roulettegerman",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulettegerman.jpg",
    //     alt: "",
    //     name: "Roulette German",
    //     providerName: "Pragmatic Play",
    //   },
    //   RouletteRussian: {
    //     redirectUrl: "/casino/ezugi/RouletteRussian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette-Russian.jpg",
    //     alt: "",
    //     name: "Roulette Russian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette3Macao: {
    //     redirectUrl: "/casino/ezugi/Roulette3Macao",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette3Macao.jpg",
    //     alt: "",
    //     name: "Roulette Macao",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette9TheClub: {
    //     redirectUrl: "/casino/ezugi/Roulette9TheClub",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette9TheClub.jpg",
    //     alt: "",
    //     name: "Roulette The Club",
    //     providerName: "Pragmatic Play",
    //   },
    //   roulette7italian: {
    //     redirectUrl: "/casino/ezugi/roulette7italian",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/roulette7italian.jpg",
    //     alt: "",
    //     name: "Roulette 7 Italian",
    //     providerName: "Pragmatic Play",
    //   },
    //   Roulette10Ruby: {
    //     redirectUrl: "/casino/ezugi/Roulette10Ruby",
    //     code: "224",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/Roulette10Ruby.jpg",
    //     alt: "",
    //     name: "Roulette 10 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Roulette",
  },
  baccarattab: {
    slide1: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide2: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    // slide3: {
    //   NoCommSpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/NoCommSpeedBaccarat1",
    //     code: "436",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/NoCommSpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "No Commission Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   SpeedBaccarat1: {
    //     redirectUrl: "/casino/ezugi/SpeedBaccarat1",
    //     code: "566",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/roulette/SpeedBaccarat1.jpg",
    //     alt: "",
    //     name: "Speed Baccarat 1",
    //     providerName: "Pragmatic Play",
    //   },

    //   // speedbaccarat5: {
    //   //   redirectUrl: "/casino/ezugi/speedbaccarat5",
    //   //   code: "570",
    //   //   casino: "ezugi",
    //   //   provider: "",
    //   //   homeUrl: pageUrlGenerator("/Casino"),
    //   //   imgUrl: "./images/roulette/speedbaccarat5.jpg",
    //   //   alt: "",
    //   //   name: "Speed Baccarat 5",
    //   //   providerName: "Pragmatic Play",
    //   // },
    // },
    name: "Baccarat",
  },

  Blackjacktab: {
    slide1: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },

      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      FreebetBlackjack: {
        redirectUrl: "/casino/ezugi/FreebetBlackjack",
        code: "1000066",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
        alt: "",
        name: "Freebet Blackjack",
        providerName: "Evolution",
      },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },

      ClassicSpeedBlackjack1: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack1",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide2: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // Rumbablackjacks2: {
      //   redirectUrl: "/casino/ezugi/Rumbablackjacks2",
      //   code: "412",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-2.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 2",
      //   providerName: "Ezugi",
      // },
      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      // AllStarsBlackjackGold1: {
      //   redirectUrl: "/casino/ezugi/AllStarsBlackjackGold1",
      //   code: "504",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
      //   alt: "",
      //   name: "All Stars Blackjack",
      //   providerName: "Ezugi",
      // },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },

    // slide3: {
    //   Blackjack17Azure: {
    //     redirectUrl: "/casino/ezugi/Blackjack17Azure",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack17Azure.jpg",
    //     alt: "",
    //     name: "Blackjack 17 Azure",
    //     providerName: "Pragmatic Play",
    //   },
    //   GoldBlackjack: {
    //     redirectUrl: "/casino/ezugi/GoldBlackjack",
    //     code: "1",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack02.jpg",
    //     alt: "",
    //     name: "Blackjack Gold 5",
    //     providerName: "Ezugi",
    //   },
    //   Blackjack39Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack39Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack39Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 39 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack42Ruby: {
    //     redirectUrl: "/casino/ezugi/Blackjack42Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack42Ruby.jpg",
    //     alt: "",
    //     name: "Blackjack 42 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    //   UnlimitedBlackjack: {
    //     redirectUrl: "/casino/ezugi/UnlimitedBlackjack",
    //     code: "51",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Unlimited-Blackjack.jpg",
    //     alt: "",
    //     name: "Unlimited Blackjack",
    //     providerName: "Ezugi",
    //   },

    //   BlackjackVIPO: {
    //     redirectUrl: "/casino/ezugi/BlackjackVIPO",
    //     code: "1000058",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack-VIP-O.jpg",
    //     alt: "",
    //     name: "Blackjack VIP O",
    //     providerName: "Evolution",
    //   },
    //   Blackjack11: {
    //     redirectUrl: "/casino/ezugi/Blackjack11",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack11.jpg",
    //     alt: "",
    //     name: "Blackjack 11",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack14: {
    //     redirectUrl: "/casino/ezugi/Blackjack14",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack14.jpg",
    //     alt: "",
    //     name: "Blackjack 14",
    //     providerName: "Pragmatic Play",
    //   },
    //   Blackjack16: {
    //     redirectUrl: "/casino/ezugi/Blackjack16",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/Blackjack16.jpg",
    //     alt: "",
    //     name: "Blackjack 16",
    //     providerName: "Pragmatic Play",
    //   },
    //   OneBlackjack: {
    //     redirectUrl: "/casino/ezugi/OneBlackjack",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/OneBlackjack.jpg",
    //     alt: "",
    //     name: "One Blackjack",
    //     providerName: "Pragmatic Play",
    //   },
    //   ONEBlackjack2: {
    //     redirectUrl: "/casino/ezugi/ONEBlackjack2",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/ONEBlackjack2.jpg",
    //     alt: "",
    //     name: "One Blackjack 2",
    //     providerName: "Pragmatic Play",
    //   },
    //   SpeedBlackjack3Ruby: {
    //     redirectUrl: "/casino/ezugi/SpeedBlackjack3Ruby",
    //     code: "1000044",
    //     casino: "ezugi",
    //     provider: "",
    //     homeUrl: pageUrlGenerator("/Casino"),
    //     imgUrl: "./images/blackjack/SpeedBlackjack3Ruby.jpg",
    //     alt: "",
    //     name: "Speed Blackjack 3 Ruby",
    //     providerName: "Pragmatic Play",
    //   },
    // },
    name: "Blackjack",
  },
  pokertab: {
    slide1: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti1",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    name: "Poker",
  },

  spribe: {
    slide1: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },

    name: "Spribe",
  },

  Supernowa: {
    slide1: {
      playerTP: {
        redirectUrl: "/casino/supernowa/playerTP",
        code: "D3TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/3PlayerTeenPatti.jpg",
        alt: "",
        name: "3 Player TeenPatti",
        providerName: "Supernowa",
      },
      Cards32SN: {
        redirectUrl: "/casino/supernowa/Cards32SN",
        code: "C32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/32Card.jpg",
        alt: "",
        name: "32 Cards",
        providerName: "Supernowa",
      },
      AkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/AkbarRomeoWalter",
        code: "ARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AkbarRomeoWalter.jpg",
        alt: "",
        name: "Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      AndarBaharSN: {
        redirectUrl: "/casino/supernowa/AndarBaharSN",
        code: "ABC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/AndarBahar.jpg",
        alt: "",
        name: "Andar Bahar",
        providerName: "Supernowa",
      },
      BaccaratSN: {
        redirectUrl: "/casino/supernowa/BaccaratSN",
        code: "BAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Baccarat.jpg",
        alt: "",
        name: "Baccarat",
        providerName: "Supernowa",
      },
      CasinoQueen: {
        redirectUrl: "/casino/supernowa/CasinoQueen",
        code: "CQ",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/CasinoQueen.jpg",
        alt: "",
        name: "Casino Queen",
        providerName: "Supernowa",
      },
      ClassicAndarBaharSN: {
        redirectUrl: "/casino/supernowa/ClassicAndarBaharSN",
        code: "AB",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/ClassicAndarBahar.jpg",
        alt: "",
        name: "Classic Andar Bahar",
        providerName: "Supernowa",
      },
      DragonTigerSN: {
        redirectUrl: "/casino/supernowa/DragonTigerSN",
        code: "DT7M",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/DragonTiger.jpg",
        alt: "",
        name: "Dragon Tiger",
        providerName: "Supernowa",
      },
      GoaAndarBaharSN: {
        redirectUrl: "/casino/supernowa/GoaAndarBaharSN",
        code: "AB2",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/GoasAndarBahar.jpg",
        alt: "",
        name: "Goa's Andar Bahar",
        providerName: "Supernowa",
      },
      KingRace: {
        redirectUrl: "/casino/supernowa/KingRace",
        code: "CR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/KingRace.jpg",
        alt: "",
        name: "King Race",
        providerName: "Supernowa",
      },
      Lucky7SN: {
        redirectUrl: "/casino/supernowa/Lucky7SN",
        code: "UD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Lucky7.jpg",
        alt: "",
        name: "Lucky 7",
        providerName: "Supernowa",
      },
      PokerSN: {
        redirectUrl: "/casino/supernowa/PokerSN",
        code: "PK",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Poker.jpg",
        alt: "",
        name: "Poker",
        providerName: "Supernowa",
      },
    },
    slide2: {
      RouletteSN: {
        redirectUrl: "/casino/supernowa/RouletteSN",
        code: "RT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Roulette.jpg",
        alt: "",
        name: "Roulette",
        providerName: "Supernowa",
      },
      TeenPattiSN: {
        redirectUrl: "/casino/supernowa/TeenPattiSN",
        code: "TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/Teen-Patti.jpg",
        alt: "",
        name: "Teen Patti",
        providerName: "Supernowa",
      },
      TeenPatti2020SN: {
        redirectUrl: "/casino/supernowa/TeenPatti2020SN",
        code: "TP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPatti2020.jpg",
        alt: "",
        name: "Teen Patti 2020",
        providerName: "Supernowa",
      },
      TeenPattiFaceOffSN: {
        redirectUrl: "/casino/supernowa/TeenPattiFaceOffSN",
        code: "TPFO",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/TeenPattiFaceOff.jpg",
        alt: "",
        name: "Teen Patti Face Off",
        providerName: "Supernowa",
      },
      WorliMatka: {
        redirectUrl: "/casino/supernowa/WorliMatka",
        code: "WM",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/WorliMatka.jpg",
        alt: "",
        name: "Worli Matka",
        providerName: "Supernowa",
      },
      RNG3CardsJudgement: {
        redirectUrl: "/casino/supernowa/RNG3CardsJudgement",
        code: "V3CJ",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG3CardsJudgement.jpg",
        alt: "",
        name: "RNG 3 Cards Judgement",
        providerName: "Supernowa",
      },
      RNG3PlayerTeenPatti: {
        redirectUrl: "/casino/supernowa/RNG3PlayerTeenPatti",
        code: "V3TP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG3PlayerTeenPatti.jpg",
        alt: "",
        name: "RNG 3 Player Teen Patti",
        providerName: "Supernowa",
      },
      RNG32Cards: {
        redirectUrl: "/casino/supernowa/RNG32Cards",
        code: "VC32",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG32Cards.jpg",
        alt: "",
        name: "RNG 32 Cards",
        providerName: "Supernowa",
      },
      RNGAkbarRomeoWalter: {
        redirectUrl: "/casino/supernowa/RNGAkbarRomeoWalter",
        code: "VARW",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGAkbarRomeoWalter.jpg",
        alt: "",
        name: "RNG Akbar Romeo Walter",
        providerName: "Supernowa",
      },
      RNGAndarBahar2020: {
        redirectUrl: "/casino/supernowa/RNGAndarBahar2020",
        code: "VAB20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGAndarBahar2020.jpg",
        alt: "",
        name: "RNG Andar Bahar 2020",
        providerName: "Supernowa",
      },
      RNGBaccarat: {
        redirectUrl: "/casino/supernowa/RNGBaccarat",
        code: "VBAC",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGBaccarat.jpg",
        alt: "",
        name: "RNG Baccarat",
        providerName: "Supernowa",
      },
      RNGBaccarat2020: {
        redirectUrl: "/casino/supernowa/RNGBaccarat2020",
        code: "VBAC20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGBaccarat2020.jpg",
        alt: "",
        name: "RNG Baccarat 2020",
        providerName: "Supernowa",
      },
    },
    slide3: {
      RNGCasinoQueen: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen",
        code: "RCQ",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
        alt: "",
        name: "RNG Casino Queen",
        providerName: "Supernowa",
      },
      RNGCasinoQueen2020: {
        redirectUrl: "/casino/supernowa/RNGCasinoQueen2020",
        code: "RCQ20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
        alt: "",
        name: "RNG Casino Queen 2020",
        providerName: "Supernowa",
      },
      RNGCricket2020: {
        redirectUrl: "/casino/supernowa/RNGCricket2020",
        code: "RCKT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGCricket2020.jpg",
        alt: "",
        name: "RNG Cricket 2020",
        providerName: "Supernowa",
      },
      RNGDragonTiger: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger",
        code: "VDT",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
        alt: "",
        name: "RNG Dragon Tiger",
        providerName: "Supernowa",
      },
      RNGDragonTiger2020: {
        redirectUrl: "/casino/supernowa/RNGDragonTiger2020",
        code: "VDT20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
        alt: "",
        name: "RNG Dragon Tiger 2020",
        providerName: "Supernowa",
      },
      RNGJoker: {
        redirectUrl: "/casino/supernowa/RNGJoker",
        code: "VJKR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGJoker.jpg",
        alt: "",
        name: "RNG Joker",
        providerName: "Supernowa",
      },
      RNGKabutarFuddi: {
        redirectUrl: "/casino/supernowa/RNGKabutarFuddi",
        code: "VKF",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGKabutarFuddi.jpg",
        alt: "",
        name: "RNG Kabutar Fuddi",
        providerName: "Supernowa",
      },
      RNGKingRace: {
        redirectUrl: "/casino/supernowa/RNGKingRace",
        code: "VCR",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGKingRace.jpg",
        alt: "",
        name: "RNG King Race",
        providerName: "Supernowa",
      },
      RNGLucky7: {
        redirectUrl: "/casino/supernowa/RNGLucky7",
        code: "VUD7",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGLucky7.jpg",
        alt: "",
        name: "RNG Lucky 7",
        providerName: "Supernowa",
      },
      RNGPoker: {
        redirectUrl: "/casino/supernowa/RNGPoker",
        code: "VPK",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGPoker.jpg",
        alt: "",
        name: "RNG Poker",
        providerName: "Supernowa",
      },
      RNGPoker2020: {
        redirectUrl: "/casino/supernowa/RNGPoker2020",
        code: "VPK20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGPoker2020.jpg",
        alt: "",
        name: "RNG Poker 2020",
        providerName: "Supernowa",
      },
      RNGTeenPatti: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti",
        code: "VTP",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
        alt: "",
        name: "RNG Teen Patti",
        providerName: "Supernowa",
      },
      RNGTeenPatti2020: {
        redirectUrl: "/casino/supernowa/RNGTeenPatti2020",
        code: "VTP20",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
        alt: "",
        name: "RNG Teen Patti 2020",
        providerName: "Supernowa",
      },
      RNGWorliMatka: {
        redirectUrl: "/casino/supernowa/RNGWorliMatka",
        code: "VWM",
        casino: "wco",
        provider: "SN",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
        alt: "",
        name: "RNG Worli Matka",
        providerName: "Supernowa",
      },
    },

    name: "Supernowa",
  },
  Vivo: {
    slide1: {
      CoffeeWild: {
        redirectUrl: "/casino/vivo-dragon-tiger",
        imgUrl: "./images/Vivo/DragonTiger.jpg",
        name: "Dragon Tiger",
        providerName: "Vivo",
      },
      andarBahar: {
        redirectUrl: "/casino/vivo-andar-bahar",
        imgUrl: "./images/Vivo/AndarBahar.jpg",
        name: "Andar bahar",
        providerName: "Vivo",
      },
      teenPatti: {
        redirectUrl: "/casino/vivo-teen-patti",
        imgUrl: "./images/Vivo/TeenPattivivo.jpg",
        name: "Teen Patti",
        providerName: "Vivo",
      },
      EuropeanAutoRoulette: {
        redirectUrl: "/casino/vivo-european-auto-roulette",
        imgUrl: "./images/Vivo/EuropeanAutoRoulette.jpg",
        name: "European Auto Roulette",
        providerName: "Vivo",
      },
      FrenchRoulette: {
        redirectUrl: "/casino/vivo-french-roulette",
        imgUrl: "./images/Vivo/FrenchRoulette.jpg",
        name: "French Roulette",
        providerName: "Vivo",
      },
      GalaxyBaccarat1: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-1",
        imgUrl: "./images/Vivo/GalaxyBaccarat1.jpg",
        name: "Galaxy Baccarat1",
        providerName: "Vivo",
      },
      GalaxyBaccarat2: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-2",
        imgUrl: "./images/Vivo/GalaxyBaccarat2.jpg",
        name: "Galaxy Baccarat 2",
        providerName: "Vivo",
      },
      GalaxyBaccarat3: {
        redirectUrl: "/casino/vivo-galaxy-baccarat-3",
        imgUrl: "./images/Vivo/GalaxyBaccarat3.jpg",
        name: "Galaxy Baccarat 3",
        providerName: "Vivo",
      },
      GalaxyRoulette: {
        redirectUrl: "/casino/vivo-galaxy-roulette",
        imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
        name: "Galaxy Roulette",
        providerName: "Vivo",
      },
      LasVegasBlackjack: {
        redirectUrl: "/casino/vivo-las-vegas-blackjack",
        imgUrl: "./images/Vivo/LasVegasBlackjack.jpg",
        name: "Las Vegas Blackjack",
        providerName: "Vivo",
      },
      LasVegasRoulette: {
        redirectUrl: "/casino/vivo-las-vegas-roulette",
        imgUrl: "./images/Vivo/LasVegasRoulette.jpg",
        name: "Las Vegas Roulette",
        providerName: "Vivo",
      },
      MacauBaccarat: {
        redirectUrl: "/casino/vivo-macau-baccarat",
        imgUrl: "./images/Vivo/MacauBaccarat.jpg",
        name: "Macau Baccarat",
        providerName: "Vivo",
      },
      Oracle360Roulette: {
        redirectUrl: "/casino/vivo-oracle-360-roulette",
        imgUrl: "./images/Vivo/Oracle360Roulette.jpg",
        name: "Oracle 360 Roulette",
        providerName: "Vivo",
      },
      OracleBlazeRoulette: {
        redirectUrl: "/casino/vivo-oracle-blaze-roulette",
        imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
        name: "Oracle Blaze Roulette",
        providerName: "Vivo",
      },
      PortomasoRoulette: {
        redirectUrl: "/casino/vivo-portomaso-roulette",
        imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
        name: "Portomaso Roulette",
        providerName: "Vivo",
      },
    },
    slide2: {
      VABacarat1: {
        redirectUrl: "/casino/vivo-va-bacarat-1",
        imgUrl: "./images/Vivo/VABacarat1.jpg",
        name: "VA Bacarat 1",
        providerName: "Vivo",
      },
      VABacarat2: {
        redirectUrl: "/casino/vivo-va-bacarat-2",
        imgUrl: "./images/Vivo/VABacarat2.jpg",
        name: "VA Bacarat 2",
        providerName: "Vivo",
      },
      VABacarat3: {
        redirectUrl: "/casino/vivo-va-bacarat-3",
        imgUrl: "./images/Vivo/VABacarat3.jpg",
        name: "VA Bacarat 3",
        providerName: "Vivo",
      },
      VABacarat4: {
        redirectUrl: "/casino/vivo-va-bacarat-4",
        imgUrl: "./images/Vivo/VABacarat4.jpg",
        name: "VA Bacarat 4",
        providerName: "Vivo",
      },
      VABacarat5: {
        redirectUrl: "/casino/vivo-va-bacarat-5",
        imgUrl: "./images/Vivo/VABacarat5.jpg",
        name: "VA Bacarat 5",
        providerName: "Vivo",
      },
    },
    name: "Vivo",
  },
  PragmaticPlay: {
    slide1: {
      AndarBaharPragamatic: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1024",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/pragmatic/AndarBahar.png",
        alt: "",
        name: "Andar Bahar",
        providerName: "Pragmatic Play",
      },
    },
    name: "PagmaticPlay",
  },

  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },

      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "TV Games",
  },

  AllGames: {
    slide1: {
      SpeedRoulette: {
        redirectUrl: "/casino/ezugi/SpeedRoulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
        alt: "",
        name: "Speed Roulette",
        providerName: "Evolution",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezugi/DiamondRoulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/DiamondRoulette.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezugi/NamasteRoulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Namasteroulette.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
      },
      AutoRoulette: {
        redirectUrl: "/casino/ezugi/AutoRoulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/AutoRoulette.jpg",
        alt: "",
        name: "Auto Roulette",
        providerName: "Ezugi",
      },
      SpeedAutoRoulette: {
        redirectUrl: "/casino/ezugi/SpeedAutoRoulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Speedautoroulleteevo.jpg",
        alt: "",
        name: "Speed Auto Roulette",
        providerName: "Evolution",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezugi/DoubleBallRoulette",
        code: "1000084",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Doubleball.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezugi/FirstPersonAmericanRoulette1",
        code: "1000312",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/firstpersonamericanrouletterevo.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezugi/FirstPersonRoulette",
        code: "1000118",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Firstpersonroulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
      },
      ImmersiveRoulette: {
        redirectUrl: "/casino/ezugi/ImmersiveRoulette",
        code: "1000122",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Immersive.jpg",
        alt: "",
        name: "Immersive Roulette",
        providerName: "Evolution",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezugi/LightningRoulette",
        code: "1000092",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Lightiningroulette.jpg",
        alt: "",
        name: "Lightning Roulette",
        providerName: "Evolution",
      },
      TurkceRoulette: {
        redirectUrl: "/casino/ezugi/TurkceRoulette",
        code: "501000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Turkce-Rulet.jpg",
        alt: "",
        name: "Türkçe Rulet",
        providerName: "Ezugi",
      },
      RuletkaRoulette: {
        redirectUrl: "/casino/ezugi/RuletkaRoulette",
        code: "601000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Ruletka-Russia.jpg",
        alt: "",
        name: "Ruletka Russia Roulette",
        providerName: "Ezugi",
      },
    },
    slide2: {
      // SlavyankaRoulette: {
      //   redirectUrl: "/casino/ezugi/SlavyankaRoulette",
      //   code: "421002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Slavyanka-Roulette.jpg",
      //   alt: "",
      //   name: "Slavyanka Roulette",
      //   providerName: "Ezugi",
      // },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezugi/PrestigeAutoRoulette",
        code: "221004",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
      },
      // PortomasoRoulette2: {
      //   redirectUrl: "/casino/ezugi/PortomasoRoulette2",
      //   code: "611004",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette1.jpg",
      //   alt: "",
      //   name: "Portomaso Real Casino Roulette",
      //   providerName: "Ezugi",
      // },
      PortomasoRoulette: {
        redirectUrl: "/casino/ezugi/PortomasoRoulette",
        code: "611000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Portomaso-Real-Casino-Roulette2.jpg",
        alt: "",
        name: "Portomaso Real Casino Roulette 2",
        providerName: "Ezugi",
      },
      OracleRoulette: {
        redirectUrl: "/casino/ezugi/OracleRoulette",
        code: "611003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Roulette-360.jpg",
        alt: "",
        name: "Oracle Roulette 360",
        providerName: "Ezugi",
      },
      OracleCasinoRoulette: {
        redirectUrl: "/casino/ezugi/OracleCasinoRoulette",
        code: "611001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Oracle-Casino-Roulette.jpg",
        alt: "",
        name: "Oracle Casino Roulette",
        providerName: "Ezugi",
      },
      // OracleBlazeRoulette: {
      //   redirectUrl: "/casino/ezugi/OracleBlazeRoulette",
      //   code: "611006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Oracle-Blaze.jpg",
      //   alt: "",
      //   name: "Oracle Blaze Roulette",
      //   providerName: "Ezugi",
      // },
      CasinoMarinaRoulette2: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette2",
        code: "321001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette-2.jpg",
        alt: "",
        name: "Casino Marina Roulette 2",
        providerName: "Ezugi",
      },
      CasinoMarinaRoulette: {
        redirectUrl: "/casino/ezugi/CasinoMarinaRoulette",
        code: "321000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/roulette/Casino-Marina-Roulette.jpg",
        alt: "",
        name: "Casino Marina Roulette",
        providerName: "Ezugi",
      },
    },
    slide3: {
      Baccarat: {
        redirectUrl: "/casino/ezugi/Baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat08.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
      },
      Nocommissiobaccarat: {
        redirectUrl: "/casino/ezugi/Nocommissiobaccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat02.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Evolution",
      },
      Knockoutbaccarat: {
        redirectUrl: "/casino/ezugi/Knockoutbaccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat03.jpg",
        alt: "",
        name: "Knock Out Baccarart",
        providerName: "Ezugi",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezugi/Super6baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat04.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezugi/Marinabaccarat",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat05.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
      },

      Marina02baccarat: {
        redirectUrl: "/casino/ezugi/Marina02baccarat",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/baccarat06.jpg",
        alt: "",
        name: "Casino Marina Baccarart 2",
        providerName: "Ezugi",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezugi/NoCommissionBaccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
        alt: "",
        name: "No Commission Baccarart",
        providerName: "Ezugi",
      },

      SpeedFortuneBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedFortuneBaccarat",
        code: "105",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
        alt: "",
        name: " Speed Fortune Baccarart",
        providerName: "Ezugi",
      },
      CricketWarBaccarat: {
        redirectUrl: "/casino/ezugi/CricketWarBaccarat",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Cricket-War.jpg",
        alt: "",
        name: " Cricket War",
        providerName: "Evolution",
      },
    },
    slide4: {
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratR",
        code: "1000270",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccarat.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratA",
        code: "1000021",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezugi/SPEEDBACCARATB",
        code: "1000022",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Ezugi",
      },
      SpeedBaccarartF: {
        redirectUrl: "/casino/ezugi/SpeedBaccarartF",
        code: "1000026",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratF.jpg",
        alt: "",
        name: "Speed Baccarat F",
        providerName: "Evolution",
      },
      SpeedbaccratQ: {
        redirectUrl: "/casino/ezugi/SpeedbaccratQ",
        code: "1000269",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratq.jpg",
        alt: "",
        name: "Speed Baccarat Q",
        providerName: "Evolution",
      },

      SpeedBaccaratS: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratS",
        code: "1000271",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedBaccaratsevo.jpg",
        alt: "",
        name: "Speed Baccarat S",
        providerName: "Evolution",
      },
      SpeedCricketBaccarat: {
        redirectUrl: "/casino/ezugi/SpeedCricketBaccarat",
        code: "101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarat",
        providerName: "Ezugi",
      },
      SpeedBaccaratL: {
        redirectUrl: "/casino/ezugi/SpeedBaccaratL",
        code: "1000276",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/SpeedbaccaratL.jpg",
        alt: "",
        name: "Speed Baccarat L",
        providerName: "Evolution",
      },
      LightBaccarat: {
        redirectUrl: "/casino/ezugi/LightBaccarat",
        code: "1000149",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/lightning-baccarat.jpg",
        alt: "",
        name: "Lightning Baccarat",
        providerName: "Evolution",
      },
      FirstPersonLightBaccarat: {
        redirectUrl: "/casino/ezugi/FirstPersonLightBaccarat",
        code: "1000145",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/baccarat/Firstpersonlightningbaccarat.jpg",
        alt: "",
        name: "First Person Lightning Baccarat",
        providerName: "Evolution",
      },
    },
    slide5: {
      Blackjack: {
        redirectUrl: "/casino/ezugi/Blackjack",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack01.jpg",
        alt: "",
        name: "Blackjack",
        providerName: "Ezugi",
      },

      BlackjackPro: {
        redirectUrl: "/casino/ezugi/BlackjackPro",
        code: "224",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-surrender-blackjack.jpg",
        alt: "",
        name: "VIP Surrender Blackjack",
        providerName: "Ezugi",
      },
      VIPBlackjack: {
        redirectUrl: "/casino/ezugi/VIPBlackjack",
        code: "225",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/vip-diamond-blackjack.jpg",
        alt: "",
        name: "VIP Diamond Blackjack",
        providerName: "Ezugi",
      },
      ItalianBlackjack: {
        redirectUrl: "/casino/ezugi/ItalianBlackjack",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/italian-blackjack.jpg",
        alt: "",
        name: "Italian Blackjack",
        providerName: "Ezugi",
      },
      TurkceBlackjack: {
        redirectUrl: "/casino/ezugi/TurkceBlackjack",
        code: "501",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/turkish-blackjack-3.jpg",
        alt: "",
        name: "Turkce Blackjack",
        providerName: "Ezugi",
      },
      BlackjackVIPA: {
        redirectUrl: "/casino/ezugi/BlackjackVIPA",
        code: "1000048",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-A.jpg",
        alt: "",
        name: "Blackjack VIP A",
        providerName: "Evolution",
      },
      FreebetBlackjack: {
        redirectUrl: "/casino/ezugi/FreebetBlackjack",
        code: "1000066",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Free-Bet-Blackjack.jpg",
        alt: "",
        name: "Freebet Blackjack",
        providerName: "Evolution",
      },

      InfiniteBlackjackGold: {
        redirectUrl: "/casino/ezugi/InfiniteBlackjackGold",
        code: "1000067",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Infinite-Blackjack.jpg",
        alt: "",
        name: "Infinite Blackjack",
        providerName: "Evolution",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ezugi/BlackjackGold4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Gold-4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
      },
      BlackjackPlatinum: {
        redirectUrl: "/casino/ezugi/BlackjackPlatinum",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Platinum-1.jpg",
        alt: "",
        name: "Platinum Blackjack  ",
        providerName: "Ezugi",
      },

      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D.jpg",
        alt: "",
        name: " Classic Speed Blackjack 1",
        providerName: "Evolution",
      },
    },
    slide6: {
      BlackjackSilverD: {
        redirectUrl: "/casino/ezugi/BlackjackSilverD",
        code: "1000044",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
        alt: "",
        name: "Blackjack Silver D",
        providerName: "Evolution",
      },
      BlackjackVIPE: {
        redirectUrl: "/casino/ezugi/BlackjackVIPE",
        code: "1000052",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
        alt: "",
        name: "Blackjack VIP E",
        providerName: "Evolution",
      },

      // RumbaBlackjack4: {
      //   redirectUrl: "/casino/ezugi/RumbaBlackjack4",
      //   code: "414",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/blackjack/Rumba-Blackjack-4.jpg",
      //   alt: "",
      //   name: "Rumba Blackjack 4",
      //   providerName: "Ezugi",
      // },
      SalonPriveBlackjackC: {
        redirectUrl: "/casino/ezugi/SalonPriveBlackjackC",
        code: "1000070",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
        alt: "",
        name: "Salon Prive Blackjack C",
        providerName: "Evolution",
      },
      SpeedBlackjackD: {
        redirectUrl: "/casino/ezugi/SpeedBlackjackD",
        code: "1000033",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
        alt: "",
        name: "Speed Blackjack D",
        providerName: "Evolution",
      },
      AllStarsBlackjackGold: {
        redirectUrl: "/casino/ezugi/AllStarsBlackjackGold",
        code: "504",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/All-Stars-Blackjack.jpg",
        alt: "",
        name: "All Stars Blackjack",
        providerName: "Ezugi",
      },
      BlackjackVIPL: {
        redirectUrl: "/casino/ezugi/BlackjackVIPL",
        code: "1000056",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
        alt: "",
        name: "Blackjack VIP L",
        providerName: "Evolution",
      },

      ClassicSpeedBlackjack3: {
        redirectUrl: "/casino/ezugi/ClassicSpeedBlackjack3",
        code: "1000228",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
        alt: "",
        name: " Classic Speed Blackjack 3  ",
        providerName: "Evolution",
      },
      // Blackjack17Azure: {
      //   redirectUrl: "/casino/ezugi/Blackjack17Azure",
      //   code: "521",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/Blackjack17Azure.jpg",
      //   alt: "",
      //   name: "Casino Marina Roulette",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide7: {
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
        code: "1000073",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/2handcasinoholem.jpg",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezugi/BetonTeenPatti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Betonteenpatti.jpg",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
      },
      Onedayteenpatti: {
        redirectUrl: "/casino/ezugi/Onedayteenpatti",
        code: "227102",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Onedayteenpatti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezugi/SideBetCity",
        code: "1000115",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Sidebetcity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezugi/TeenPatti3card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/Teenpatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezugi/TexasHoldembonus",
        code: "1000111",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/TexasHoldembonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezugi/Ultimatetexasholdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/poker/ultimatetaxasholden.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
      },
      // MegaSicBoLobby: {
      //   redirectUrl: "/casino/ezugi/MegaSicBoLobby",
      //   code: "107",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/Casino"),
      //   imgUrl: "./images/roulette/MegaSicBoLobby.jpg",
      //   alt: "",
      //   name: "Mega Sic Bo Lobby",
      //   providerName: "Pragmatic Play",
      // },
    },
    slide8: {
      aviator: {
        redirectUrl: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/aviator.gif",
        alt: "",
        name: "Aviator",
        providerName: "Spribe",
      },
      mines: {
        redirectUrl: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mines.jpg",
        alt: "",
        name: "Mines",
        providerName: "Spribe",
      },
      goal: {
        redirectUrl: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Goal.jpg",
        alt: "",
        name: "Goal",
        providerName: "Spribe",
      },
      dice: {
        redirectUrl: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Dice.jpg",
        alt: "",
        name: "Dice",
        providerName: "Spribe",
      },
      plinko: {
        redirectUrl: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Plinko.jpg",
        alt: "",
        name: "Plinko",
        providerName: "Spribe",
      },
      miniroulette: {
        redirectUrl: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/Mini-roulette.jpg",
        alt: "",
        name: "Mini Roulette",
        providerName: "Spribe",
      },
      hilo: {
        redirectUrl: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/spribe/HIlo.jpg",
        alt: "",
        name: "Hilo",
        providerName: "Spribe",
      },
    },
    slide9: {
      CrazyTime: {
        redirectUrl: "/casino/ezugi/CrazyTime",
        code: "1000148",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CrazyTime.jpg",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
      },
      DreamCatcher: {
        redirectUrl: "/casino/ezugi/DreamCatcher",
        code: "1000077",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/DreamCatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "Evolution",
      },
      FootballStudio: {
        redirectUrl: "/casino/ezugi/FootballStudio",
        code: "1000112",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/FootballStudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
      },

      Megaball: {
        redirectUrl: "/casino/ezugi/Megaball",
        code: "1000141",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
      },
      CashorCrash: {
        redirectUrl: "/casino/ezugi/CashorCrash",
        code: "1000352",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/CashorCrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezugi/OTTAndarBahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/OTTAndarBahar.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
      },
      SicBo: {
        redirectUrl: "/casino/ezugi/SicBo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/SicBo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezugi/UltimateSicBo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/Casino"),
        imgUrl: "./images/tvshows/UltimateSicBo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
      },
    },

    name: "All Games",
  },

  // thirtytwoCardtab: {
  //   thirtytwoCards: {
  //     redirectUrl: "/casino/ezugi/thirtytwoCards",
  //     code: "228002",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/card/32cards01.png",
  //     alt: "",
  //   },
  // },
  // sevenLuckytab: {
  //   sevenLucky: {
  //     redirectUrl: "/casino/ezugi/sevenLucky",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/lucky7/Lucky011.png",
  //     alt: "",
  //   },
  // },
  // sicbotab: {
  //   sicbodice01: {
  //     redirectUrl: "/casino/ezugi/sicbodice01",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice01.png",
  //     alt: "",
  //   },
  //   sicbodice02: {
  //     redirectUrl: "/casino/ezugi/sicbodice02",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice02.png",
  //     alt: "",
  //   },
  //   sicbodice03: {
  //     redirectUrl: "/casino/ezugi/sicbodice03",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice03.png",
  //     alt: "",
  //   },
  //   sicbodice04: {
  //     redirectUrl: "/casino/ezugi/sicbodice04",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice04.jpeg",
  //     alt: "",
  //   },
  //   sicbodice05: {
  //     redirectUrl: "/casino/ezugi/sicbodice05",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/dice/Dice05.png",
  //     alt: "",
  //   },
  // },
  // spribe: {
  //   aviator: {
  //     redirectUrl: "/casino/spribe/aviator",
  //     code: "aviator",
  //     casino: "spribe",
  //     provider: "aviator",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/aviator.gif",
  //     alt: "",
  //     name: "Aviator",
  //   },
  //   mines: {
  //     redirectUrl: "/casino/spribe/mines",
  //     code: "mines",
  //     casino: "spribe",
  //     provider: "mines",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Mines.jpg",
  //     alt: "",
  //     name: "Mines",
  //   },
  //   goal: {
  //     redirectUrl: "/casino/spribe/goal",
  //     code: "goal",
  //     casino: "spribe",
  //     provider: "goal",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Goal.jpg",
  //     alt: "",
  //     name: "Goal",
  //   },
  //   dice: {
  //     redirectUrl: "/casino/spribe/dice",
  //     code: "dice",
  //     casino: "spribe",
  //     provider: "dice",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Dice.jpg",
  //     alt: "",
  //     name: "Dice",
  //   },

  //   plinko: {
  //     redirectUrl: "/casino/spribe/plinko",
  //     code: "plinko",
  //     casino: "spribe",
  //     provider: "plinko",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Plinko.jpg",
  //     alt: "",
  //     name: "Plinko",
  //   },
  //   miniroulette: {
  //     redirectUrl: "/casino/spribe/miniroulette",
  //     code: "mini-roulette",
  //     casino: "spribe",
  //     provider: "mini-roulette",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/Mini-roulette.jpg",
  //     alt: "",
  //     name: "Mini Roulette",
  //   },
  //   hilo: {
  //     redirectUrl: "/casino/spribe/hilo",
  //     code: "hi-lo",
  //     casino: "spribe",
  //     provider: "hi-lo",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     imgUrl: "./images/spribe/HIlo.jpg",
  //     alt: "",
  //     name: "Hilo",
  //   },
  // },

  // Slots: {
  //   twinspin: {
  //     href: "/casino/ezugi/twinspin",
  //     code: "1100039",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
  //   },
  //

  //   asgardianstones: {
  //     href: "/casino/ezugi/asgardianstones",
  //     code: "1100096",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
  //   },
  //   secrets: {
  //     href: "/casino/ezugi/secrets",
  //     code: "1100106",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/secrets.jpg", alt: "" },
  //   },
  //   grandwheel: {
  //     href: "/casino/ezugi/grandwheel",
  //     code: "1100150",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
  //   },
  //   Strike777: {
  //     href: "/casino/ezugi/Strike777",
  //     code: "1100031",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Nights1000: {
  //     href: "/casino/ezugi/Nights1000",
  //     code: "1100003",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CashUltimate: {
  //     href: "/casino/ezugi/CashUltimate",
  //     code: "1100070",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CashVolt: {
  //     href: "/casino/ezugi/CashVolt",
  //     code: "1100071",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DazzleMegaways: {
  //     href: "/casino/ezugi/DazzleMegaways",
  //     code: "1100083",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DivinieFortuneMegaways: {
  //     href: "/casino/ezugi/DivinieFortuneMegaways",
  //     code: "1100089",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Dynamiteriches: {
  //     href: "/casino/ezugi/Dynamiteriches",
  //     code: "1100073",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FortuneHouse: {
  //     href: "/casino/ezugi/FortuneHouse",
  //     code: "1100007",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShopMegaways: {
  //     href: "/casino/ezugi/FruitShopMegaways",
  //     code: "1100094",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenCryptex: {
  //     href: "/casino/ezugi/GoldenCryptex",
  //     code: "1100147",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenTsar: {
  //     href: "/casino/ezugi/GoldenTsar",
  //     code: "1100149",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GorillaKingdom: {
  //     href: "/casino/ezugi/GorillaKingdom",
  //     code: "1100088",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MystryReels: {
  //     href: "/casino/ezugi/MystryReels",
  //     code: "1100061",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RageoftheSea: {
  //     href: "/casino/ezugi/RageoftheSea",
  //     code: "1100116",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelKingMegaways: {
  //     href: "/casino/ezugi/ReelKingMegaways",
  //     code: "1100052",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TurnFortune: {
  //     href: "/casino/ezugi/TurnFortune",
  //     code: "1100041",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TwinSpinMegaways: {
  //     href: "/casino/ezugi/TwinSpinMegaways",
  //     code: "1100039",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WillysChills: {
  //     href: "/casino/ezugi/WillysChills",
  //     code: "1100128",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VikingsSlots: {
  //     href: "/casino/ezugi/VikingsSlots",
  //     code: "1100124",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },

  //   ChineseTreasures: {
  //     href: "/casino/ezugi/ChineseTreasures",
  //     code: "1100006",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuck: {
  //     href: "/casino/ezugi/DragonLuck",
  //     code: "1100001",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonFire: {
  //     href: "/casino/ezugi/DragonFire",
  //     code: "1100008",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuckDeluxe: {
  //     href: "/casino/ezugi/DragonLuckDeluxe",
  //     code: "1100011",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonLuckReel: {
  //     href: "/casino/ezugi/DragonLuckReel",
  //     code: "1100002",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   EpicJourney: {
  //     href: "/casino/ezugi/EpicJourney",
  //     code: "1100012",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GodWealth: {
  //     href: "/casino/ezugi/GodWealth",
  //     code: "1100005",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyFortune: {
  //     href: "/casino/ezugi/LuckyFortune",
  //     code: "1100010",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MagicGate: {
  //     href: "/casino/ezugi/MagicGate",
  //     code: "1100009",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenTemple: {
  //     href: "/casino/ezugi/GoldenTemple",
  //     code: "1100013",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PhoenixPower: {
  //     href: "/casino/ezugi/PhoenixPower",
  //     code: "1100014",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiratesPlenty: {
  //     href: "/casino/ezugi/PiratesPlenty",
  //     code: "1100015",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenOffer: {
  //     href: "/casino/ezugi/GoldenOffer",
  //     code: "1100016",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RAsLegend: {
  //     href: "/casino/ezugi/RAsLegend",
  //     code: "1100017",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //
  //   RainbowJAcket: {
  //     href: "/casino/ezugi/RainbowJAcket",
  //     code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaPyramid: {
  //     href: "/casino/ezugi/MegaPyramid",
  //     code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Ozzy_Osbourne: {
  //     href: "/casino/ezugi/Ozzy_Osbourne",
  //     code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jimi_Hendrix: {
  //     href: "/casino/ezugi/Jimi_Hendrix",
  //     code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jimi_Hendrix: {
  //     href: "/casino/ezugi/Jimi_Hendrix",
  //     code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Jumanji: {
  //     href: "/casino/ezugi/Jumanji",
  //     code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MotorheadVideoSlot: {
  //     href: "/casino/ezugi/MotorheadVideoSlot",
  //     code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Starburst: {
  //     href: "/casino/ezugi/Starburst",
  //     code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WarofGod: {
  //     href: "/casino/ezugi/WarofGod",
  //     code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Christmas: {
  //     href: "/casino/ezugi/Christmas",
  //     code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ClusterPays: {
  //     href: "/casino/ezugi/ClusterPays",
  //     code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AncientsBlessing: {
  //     href: "/casino/ezugi/AncientsBlessing",
  //     code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AurumCodex: {
  //     href: "/casino/ezugi/AurumCodex",
  //     code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GunsNRoses: {
  //     href: "/casino/ezugi/GunsNRoses",
  //     code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BorisandBoo: {
  //     href: "/casino/ezugi/BorisandBoo",
  //     code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWest: {
  //     href: "/casino/ezugi/WildWest",
  //     code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SteamTower: {
  //     href: "/casino/ezugi/SteamTower",
  //     code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ZeusLightning: {
  //     href: "/casino/ezugi/ZeusLightning",
  //     code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WingsofRa: {
  //     href: "/casino/ezugi/WingsofRa",
  //     code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Trillionaire: {
  //     href: "/casino/ezugi/Trillionaire",
  //     code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TreasureMine: {
  //     href: "/casino/ezugi/TreasureMine",
  //     code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ThorVengeance: {
  //     href: "/casino/ezugi/ThorVengeance",
  //     code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RegalStreak: {
  //     href: "/casino/ezugi/RegalStreak",
  //     code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RegalBeasts: {
  //     href: "/casino/ezugi/RegalBeasts",
  //     code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelKeeper: {
  //     href: "/casino/ezugi/ReelKeeper",
  //     code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RainbowJackpotsPowerLines: {
  //     href: "/casino/ezugi/RainbowJackpotsPowerLines",
  //     code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiggyRichesMegaways: {
  //     href: "/casino/ezugi/PiggyRichesMegaways",
  //     code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleBells: {
  //     href: "/casino/ezugi/JingleBells",
  //     code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackinaPot: {
  //     href: "/casino/ezugi/JackinaPot",
  //     code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FiveStar: {
  //     href: "/casino/ezugi/FiveStar",
  //     code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BountyRaid: {
  //     href: "/casino/ezugi/BountyRaid",
  //     code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MysteryReels2: {
  //     href: "/casino/ezugi/MysteryReels2",
  //     code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DiamondBlitz: {
  //     href: "/casino/ezugi/DiamondBlitz",
  //     code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DragonFireMegaways: {
  //     href: "/casino/ezugi/DragonFireMegaways",
  //     code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Rome: {
  //     href: "/casino/ezugi/Rome",
  //     code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelRush: {
  //     href: "/casino/ezugi/ReelRush",
  //     code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BloodSuckers: {
  //     href: "/casino/ezugi/BloodSuckers",
  //     code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Pyramid: {
  //     href: "/casino/ezugi/Pyramid",
  //     code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ButterflyStaxx: {
  //     href: "/casino/ezugi/ButterflyStaxx",
  //     code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PiggyRiches: {
  //     href: "/casino/ezugi/PiggyRiches",
  //     code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DarkKing: {
  //     href: "/casino/ezugi/DarkKing",
  //     code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleSpin: {
  //     href: "/casino/ezugi/JingleSpin",
  //     code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Dazzle: {
  //     href: "/casino/ezugi/Dazzle",
  //     code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackHammer: {
  //     href: "/casino/ezugi/JackHammer",
  //     code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive: {
  //     href: "/casino/ezugi/DeadorAlive",
  //     code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackandtheBeanstalk: {
  //     href: "/casino/ezugi/JackandtheBeanstalk",
  //     code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive2: {
  //     href: "/casino/ezugi/DeadorAlive2",
  //     code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DivineFortuneMegaways: {
  //     href: "/casino/ezugi/DivineFortuneMegaways",
  //     code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GordonRamsayHellsKitchen: {
  //     href: "/casino/ezugi/GordonRamsayHellsKitchen",
  //     code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Elements: {
  //     href: "/casino/ezugi/Elements",
  //     code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //
  //   FinnandtheSwirlySpin: {
  //     href: "/casino/ezugi/FinnandtheSwirlySpin",
  //     code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShop: {
  //     href: "/casino/ezugi/FruitShop",
  //     code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   BloodSuckers2: {
  //     href: "/casino/ezugi/BloodSuckers2",
  //     code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ButterflyStaxx2: {
  //     href: "/casino/ezugi/ButterflyStaxx2",
  //     code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DazzleMeMegaways: {
  //     href: "/casino/ezugi/DazzleMeMegaways",
  //     code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DeadorAlive2FeatureBuy: {
  //     href: "/casino/ezugi/DeadorAlive2FeatureBuy",
  //     code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DoubleStacks: {
  //     href: "/casino/ezugi/DoubleStacks",
  //     code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FairytaleLegends: {
  //     href: "/casino/ezugi/FairytaleLegends",
  //     code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Flowers: {
  //     href: "/casino/ezugi/Flowers",
  //     code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitShopChristmasEdition: {
  //     href: "/casino/ezugi/FruitShopChristmasEdition",
  //     code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Hotline: {
  //     href: "/casino/ezugi/Hotline",
  //     code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Hotline2: {
  //     href: "/casino/ezugi/Hotline2",
  //     code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheInvisibleMan: {
  //     href: "/casino/ezugi/TheInvisibleMan",
  //     code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   IrishPotLuck: {
  //     href: "/casino/ezugi/IrishPotLuck",
  //     code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JackHammer2: {
  //     href: "/casino/ezugi/JackHammer2",
  //     code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FruitSpin: {
  //     href: "/casino/ezugi/FruitSpin",
  //     code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JungleSpirit: {
  //     href: "/casino/ezugi/JungleSpirit",
  //     code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   KoiPrincess: {
  //     href: "/casino/ezugi/KoiPrincess",
  //     code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DriveMultiplierMayhem: {
  //     href: "/casino/ezugi/DriveMultiplierMayhem",
  //     code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RageoftheSeas: {
  //     href: "/casino/ezugi/RageoftheSeas",
  //     code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RichesofMidgard: {
  //     href: "/casino/ezugi/RichesofMidgard",
  //     code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ScruffyDuck: {
  //     href: "/casino/ezugi/ScruffyDuck",
  //     code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SecretoftheStones: {
  //     href: "/casino/ezugi/SecretoftheStones",
  //     code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SecretsofChristmas: {
  //     href: "/casino/ezugi/SecretsofChristmas",
  //     code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SerengetiKings: {
  //     href: "/casino/ezugi/SerengetiKings",
  //     code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SpaceWars: {
  //     href: "/casino/ezugi/SpaceWars",
  //     code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Victorious: {
  //     href: "/casino/ezugi/Victorious",
  //     code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Warlords: {
  //     href: "/casino/ezugi/Warlords",
  //     code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWater: {
  //     href: "/casino/ezugi/WildWater",
  //     code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildWorlds: {
  //     href: "/casino/ezugi/WildWorlds",
  //     code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Squad4: {
  //     href: "/casino/ezugi/Squad4",
  //     code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Families5: {
  //     href: "/casino/ezugi/Families5",
  //     code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AgentRoyale: {
  //     href: "/casino/ezugi/AgentRoyale",
  //     code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ArcadeBomb: {
  //     href: "/casino/ezugi/ArcadeBomb",
  //     code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Atlantis: {
  //     href: "/casino/ezugi/Atlantis",
  //     code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   AztecSpins: {
  //     href: "/casino/ezugi/AztecSpins",
  //     code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CinderellaBall: {
  //     href: "/casino/ezugi/CinderellaBall",
  //     code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CirquedelaFortune: {
  //     href: "/casino/ezugi/CirquedelaFortune",
  //     code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ClashoftheBeasts: {
  //     href: "/casino/ezugi/ClashoftheBeasts",
  //     code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CrazyGenie: {
  //     href: "/casino/ezugi/CrazyGenie",
  //     code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   CrystalMirror: {
  //     href: "/casino/ezugi/CrystalMirror",
  //     code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DaVinciMystery: {
  //     href: "/casino/ezugi/DaVinciMystery",
  //     code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DevilNumber: {
  //     href: "/casino/ezugi/DevilNumber",
  //     code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   DiceDice: {
  //     href: "/casino/ezugi/DiceDice",
  //     code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   FiveStarsPowerReels: {
  //     href: "/casino/ezugi/FiveStarsPowerReels",
  //     code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GemsGoneWild: {
  //     href: "/casino/ezugi/GemsGoneWild",
  //     code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GemsGoneWildPowerReels: {
  //     href: "/casino/ezugi/GemsGoneWildPowerReels",
  //     code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   GoldenLeprechaunMegaways: {
  //     href: "/casino/ezugi/GoldenLeprechaunMegaways",
  //     code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   HoardofPoseidon: {
  //     href: "/casino/ezugi/HoardofPoseidon",
  //     code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JewelScarabs: {
  //     href: "/casino/ezugi/JewelScarabs",
  //     code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   JingleBellsPowerReels: {
  //     href: "/casino/ezugi/JingleBellsPowerReels",
  //     code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LegendofAthena: {
  //     href: "/casino/ezugi/LegendofAthena",
  //     code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyHalloween: {
  //     href: "/casino/ezugi/LuckyHalloween",
  //     code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyLittleDevil: {
  //     href: "/casino/ezugi/LuckyLittleDevil",
  //     code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyValentine: {
  //     href: "/casino/ezugi/LuckyValentine",
  //     code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   LuckyWizard: {
  //     href: "/casino/ezugi/LuckyWizard",
  //     code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   Masquerade: {
  //     href: "/casino/ezugi/Masquerade",
  //     code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MayanGods: {
  //     href: "/casino/ezugi/MayanGods",
  //     code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaDragon: {
  //     href: "/casino/ezugi/MegaDragon",
  //     code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaJade: {
  //     href: "/casino/ezugi/MegaJade",
  //     code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MegaRise: {
  //     href: "/casino/ezugi/MegaRise",
  //     code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MultiplierRiches: {
  //     href: "/casino/ezugi/MultiplierRiches",
  //     code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   MysteryReelsMegaways: {
  //     href: "/casino/ezugi/MysteryReelsMegaways",
  //     code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   NinjaWays: {
  //     href: "/casino/ezugi/NinjaWays",
  //     code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PathofDestiny: {
  //     href: "/casino/ezugi/PathofDestiny",
  //     code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   PersianFortune: {
  //     href: "/casino/ezugi/PersianFortune",
  //     code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ReelHeist: {
  //     href: "/casino/ezugi/ReelHeist",
  //     code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   RobinHoodWildForest: {
  //     href: "/casino/ezugi/RobinHoodWildForest",
  //     code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SnowWild: {
  //     href: "/casino/ezugi/SnowWild",
  //     code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   SylvanSpirits: {
  //     href: "/casino/ezugi/SylvanSpirits",
  //     code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheGreatestTrainRobbery: {
  //     href: "/casino/ezugi/TheGreatestTrainRobbery",
  //     code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TheWildHatter: {
  //     href: "/casino/ezugi/TheWildHatter",
  //     code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   ThorLightning: {
  //     href: "/casino/ezugi/ThorLightning",
  //     code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TikiFruits: {
  //     href: "/casino/ezugi/TikiFruits",
  //     code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   TotemLightning: {
  //     href: "/casino/ezugi/TotemLightning",
  //     code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VaultCracker: {
  //     href: "/casino/ezugi/VaultCracker",
  //     code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   VaultofAnubis: {
  //     href: "/casino/ezugi/VaultofAnubis",
  //     code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WellofWishes: {
  //     href: "/casino/ezugi/WellofWishes",
  //     code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildCatsMultiline: {
  //     href: "/casino/ezugi/WildCatsMultiline",
  //     code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildOClock: {
  //     href: "/casino/ezugi/WildOClock",
  //     code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WildChest: {
  //     href: "/casino/ezugi/WildChest",
  //     code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   WinEscalator: {
  //     href: "/casino/ezugi/WinEscalator",
  //     code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  //   YucatanMystery: {
  //     href: "/casino/ezugi/YucatanMystery",
  //     code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
  //     casino: "ezugi",
  //     provider: "",
  //      homeUrl: pageUrlGenerator("/Casino"),
  //     cover: { src: "./images/slots/777_Strike.png", alt: "" },
  //   },
  // },
};
